<template>
  <div class="viewPdf">
    <PDFViewer v-bind="{ url }" @document-errored="onDocumentErrored">
    </PDFViewer>
  </div>
</template>

<script>
import PDFViewer from '../pdfComponents/PDFViewer'
import methods from './jsModule/viewPdf'
export default {
  name: 'viewPdf',
  components: {
    PDFViewer
  },
  data() {
    return {
      url: '',
      courseId: null,
      ebookId: null,
      interval: null,
      documentError: undefined,
      deviceType: ''
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      let type = this.$store.state.deviceType;
      if(val && type == 1) {
        window.webkit.messageHandlers.getData.postMessage('pdf');
        window.getPdfUrl = this.getPdfUrl;
        window.getPdfId = this.getPdfId;
      } else if(val && type == 2) {
        this.getAndUrl();
      }
    }
  },
  mounted() {
    this.clearEvent();
  },
  methods,
}
</script>

<style lang='scss'>
  .viewPdf {
    height: 100%;
    width: 100%;
    background: #363840;
    label.form {
      color: white;
      font-family: Monaco, "Courier New", Courier, monospace;
      font-weight: bold;
      margin-bottom: 2em;
      display: block;
    }
    input {
      padding: 0.45em;
      font-size: 1em;
    }
    .error {
      border: 1px solid red;
      background: pink;
      color: red;
      padding: 0.5em 3em;
      display: inline;
    }

    a.icon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0px;
      background: transparent;
      color: #333;
      font-weight: bold;
      padding: 0.25em;
      width: 1em;
      height: 1em;
      font-size: 1.5em;
      margin: 0 10px;
    }
    a.icon svg {
      width: 100%;
      height: 100%;
    }

    .box-shadow {
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
        0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
    .overflow-hidden {
      overflow: hidden;
    }

    @media print {
      body {
        background-color: transparent;
      }
      #app {
        margin: 0;
        padding: 0;
      }
    }
  }
</style>
