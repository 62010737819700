var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdf-viewer"},[_c('header',{staticClass:"pdf-viewer__header box-shadow"},[_c('div',{staticClass:"pdf-goBack"},[_c('img',{staticClass:"revert",attrs:{"src":require("../../assets/img/goBackWhite.png"),"alt":""},on:{"click":_vm.goBack}})]),_c('PDFZoom',{staticClass:"header-item",attrs:{"scale":_vm.scale},on:{"change":_vm.updateScale,"fit":_vm.updateFit}}),_c('PDFPaginator',{staticClass:"header-item",attrs:{"pageCount":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_vm._t("header")],2),_c('PDFData',{staticClass:"pdf-viewer__main",attrs:{"url":_vm.url},on:{"page-count":_vm.updatePageCount,"page-focus":_vm.updateCurrentPage,"document-rendered":_vm.onDocumentRendered,"document-errored":_vm.onDocumentErrored},scopedSlots:_vm._u([{key:"preview",fn:function({ pages }){return [_c('PDFPreview',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isPreviewEnabled),expression:"isPreviewEnabled"}],staticClass:"pdf-viewer__preview"},'PDFPreview',{ pages, scale: _vm.scale, currentPage: _vm.currentPage, pageCount: _vm.pageCount, isPreviewEnabled: _vm.isPreviewEnabled },false))]}},{key:"document",fn:function({ pages }){return [_c('PDFDocument',_vm._b({staticClass:"pdf-viewer__document",class:{ 'preview-enabled': _vm.isPreviewEnabled },on:{"scale-change":_vm.updateScale}},'PDFDocument',{
          pages,
          scale: _vm.scale,
          optimalScale: _vm.optimalScale,
          fit: _vm.fit,
          currentPage: _vm.currentPage,
          pageCount: _vm.pageCount,
          isPreviewEnabled: _vm.isPreviewEnabled,
        },false))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }