let currentLength = 0;
const viewPdf = {
  clearEvent() {
    this.$once("hook:beforeDestroy", function () {
      let that = this;
      if (that.interval) {
        clearInterval(that.interval);
      }
      currentLength = 0;
    });
  },
  onDocumentErrored(e) {
    this.documentError = e.text;
  },
  // Android
  getAndUrl() {
    // alert('obj：'+window.Android.getEbookId())
    this.url = window.Android.getH5url()
    this.ebookId = window.Android.hasOwnProperty('getEbookId') ? window.Android.getEbookId() : null
    this.courseId = window.Android.hasOwnProperty('getEbookId') ? window.Android.getCourseId() : null
    if(this.ebookId && this.courseId) this.ebookStudy()
  },
  // ios-获取pdf预览地址
  getPdfUrl(pdfurl) {
    // alert("pdfUrl:"+pdfurl);
    this.url = pdfurl;
  },
  // ios-获取ebookId,courseId
  getPdfId(pdfObj) {
    // alert("pdfUrl:"+pdfurl);
    let obj = JSON.parse(pdfObj)
    this.ebookId = obj.ebookId;
    this.courseId = obj.courseId;
    this.ebookStudy();
  },
  ebookStudy() {
    let that = this;
    this.$request
      .getBookRecordId({ courseId: this.courseId, ebookId: this.ebookId })
      .then((res) => {
        let recordId = res.data.data;
        let interval = 5;
        that.interval = setInterval(() => {
          currentLength = currentLength + interval;
          that.postBook(recordId, currentLength, interval);
        }, interval * 1000);
      });
  },
  postBook(recordId, currentLength, interval) {
    let obj = {
      courseId: this.courseId,
      currentLength: currentLength,
      length: interval,
      recordId: recordId,
    };
    this.$request.postBook(obj).then((res) => {
      // this.recordId = res.data.data;
    });
  },
}

export default viewPdf;